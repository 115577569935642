@import "ui-library/app";

.root {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: spacing(7);
  background-color: transparent;

  @include l {
    min-width: spacing(8);
  }

  @include xl {
    min-width: spacing(10);
  }
}

.button {
  position: fixed;
  top: 50%;
  color: $c-5;
  background-color: transparent;
  transform: translateY(-50%);
}

.icon {
  width: 48px;
  height: 48px;
}
