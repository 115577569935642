@import 'ui-library/app';

.card {
  justify-content: space-between;
  background-color: rgb($c-bg-light-grey, 0.4);
}

.secondRow {
  margin-top: spacing(1.5);
}
