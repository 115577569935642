@import 'ui-library/app';

.animation {
  transition: $transition;
  transition-property: opacity, transform;
}

.animationSlideFrom {
  transform: translateX(100%);
}

.animationSlideTo {
  transform: translateX(0);
}

.animationOpacityFrom {
  visibility: hidden;
  opacity: 0;
}

.animationOpacityTo {
  transform: translateX(0);
  visibility: visible;
  opacity: 1;
}

.drawer {
  position: fixed;
  top: 0;
  right: 0;
  z-index: $zindex-modal;
  height: 100%;
}

.drawerPanel {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  color: $c-5;
  background-color: $c-1;
  outline: 0;

  @include m {
    width: 56vw;
  }

  @include l {
    width: 48vw;
  }

  @include xl {
    width: 41vw;
  }
}

.action {
  position: fixed;
  top: spacing(2);
  left: -40px - spacing(2);
  transition: $transition;
  transition-property: opacity;
}

.actionFrom {
  opacity: 0;
}

.actionTo {
  opacity: 1;
}
