// #region DEPRECATED

$c-primary: #e98530;
$c-primary-hover: #c35c04;
$c-1: #fff;
$c-2: #012b84;
$c-3: #f7f5f0;
$c-4: #f50000;
$c-5: #080d13;
$c-6: #7c7f84;
$c-7: #0551c3;
$c-8: #545252;
$c-9: #353231;
$c-10: #a2a7ae;
$c-11: #e4e4e6;
$c-12: #f2f2f4;
$c-13: #00ab00;
$shadow: 0 0 32px rgb(23 35 52 / 8%);
$backdrop: rgb(8 13 19 / 50%);

// #endregion

// #region REDESIGN

// main
$c-main-accent: #e98530;
$c-main-accent-hover: #efdac4;
$c-main-error: #e23232;
$c-main-done: #00ab00;

// text
$c-text-primary: #353231;
$c-text-secondary: #716c62;
$c-text-accent: #87682f;
$c-text-tertiary: #fff;
$c-text-title: #080d13;

// background
$c-bg-primary: #f1efe9;
$c-bg-primary-hover: #e8e3d2;
$c-bg-primary-pressed: #d8d3c5;
$c-bg-secondary: #fff;
$c-bg-secondary-hover: #edeadf;
$c-bg-tertiary: #e8e3d2;
$c-bg-dark: #353231;
$c-bg-light-grey: #d1cec6;
$c-bg-inputs: #faf8f2;

// button
$c-btn-primary: #e98530;
$c-btn-primary-hover: #c35c04;
$c-btn-primary-disabled: #adafb0;
$c-btn-primary-line: #c35c04;
$c-btn-primary-line-hover: #9b4a04;

// #endregion
