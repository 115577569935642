@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(1);
}

.button {
  width: 32px;
  height: 32px;
  background-color: $c-1; 
  border: 1px solid $c-11;
  border-radius: 4px;
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.page {
  @include font-text-regular-14(inherit);
}

.active {
  color: $c-primary;
  border-color: $c-primary;
}

.navigate {
  color: $c-1;
  background-color: $c-8;
}

.disabled {
  color: $c-1;
  background-color: $c-10;
  cursor: default;
}