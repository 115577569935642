@import 'ui-library/app';

@mixin select-options() {
  position: absolute;
  z-index: $zindex-select-options;
  width: 100%;
  max-height: 270px;
  margin: 0;
  margin-top: spacing(1);
  padding: 0;
  overflow: auto;
  list-style: none;
  background-color: $c-bg-inputs;
  border-radius: 4px;
  outline: none;
}
