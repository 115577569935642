@import "ui-library/app";

.root {
  display: flex;
  flex-direction: column;
  padding-top: spacing(5);
  padding-bottom: spacing(2);
}

.folder {
  color: $c-5;
  cursor: pointer;

  &:hover {
    color: $c-primary-hover;
  }
}

.level0 {
  @include font-h2-regular-24(inherit);
}

.level1 {
  @include font-h3(inherit);
}

.parent {
  padding-top: spacing(1);
  @include font-text-italic-14($c-10);
}