@import "ui-library/app";

$folder-card-border-radius: 4px;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $folder-card-border-radius;
}
