@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column
}

.panels {
  margin-top: 0;
}
