@import 'ui-library/app';

$border-width: 1px;

.root {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.tick {
  position: absolute;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: $c-bg-secondary;
  border: $border-width solid $c-bg-light-grey;
  border-radius: 4px;
  transition: $transition;
  transition-property: background-color, border-color;
  pointer-events: none;
}

.check {
  color: $c-text-tertiary;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  transition-property: opacity;
}

.input {
  position: absolute;
  top: -$border-width;
  left: -$border-width;
  z-index: 4;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0;

  &:hover + .tick {
    background-color: $c-bg-primary-hover;
  }

  &:checked {
    & + .tick {
      background-color: $c-main-accent;
      border-color: $c-main-accent;
    }

    & + .tick .check {
      visibility: visible;
      opacity: 1;
    }

    &:hover + .tick {
      background-color: $c-main-accent;
      border-color: $c-main-accent;
    }
  }
}
