@import 'ui-library/app';

.list {
  width: 100%;
}

.text {
  flex: 1;
}

.startAdornment {
  margin-right: spacing(2);
}

.endAdornment {
  margin-left: spacing(2);
  color: $c-text-primary;
}

.primary {
  @include text-ellipsis;
}

.listItemDanger {
  color: $c-main-error;

  &:hover {
    color: $c-main-error;
  }

  .startAdornment, .endAdornment {
    color: $c-main-error;
  }
}

.divider {
  display: block; 
  height: 1px;
  margin: spacing(1, 0);
  background-color: $c-bg-light-grey;
  border: 0;
} 