@import 'ui-library/app';
@import '../../styles/variables';

.caption {
  transition: $transition;

  @include font-h3($c-5);
}

.root {
  position: relative;
  display: flex;
  gap: spacing(3);

  &.viewSearch {
    .caption {
      color: $c-5;
    }
  }

  &:hover {
    .caption {
      color: $c-text-accent;
    }
  }
}

.image {
  position: relative;
  width: $image-size;
  height: $image-size;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  justify-content: center;
}

.author {
  @include font-text-italic-14($c-5);
}

.year {
  @include font-text-regular-14($c-6);
}
