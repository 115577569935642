@import "ui-library/app";

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  transition: $transition;

  &:disabled {
    color: $c-bg-secondary;
    background-color: $c-btn-primary-disabled;
  }

  &:not(&:disabled) {
    cursor: pointer;
  }
}

.normal {
  width: 40px;
  height: 40px;
}

.small {
  width: 24px;
  height: 24px;
}

.main {
  color: $c-bg-secondary;
  background-color: $c-text-primary;

  &:hover:not(&:disabled) {
    color: $c-bg-secondary-hover;
    background-color: $c-text-secondary;
  }
}

.secondary {
  background-color: $c-bg-secondary;
}

.transparent {
  background-color: transparent;
}

