@import 'ui-library/app';

.root {
  flex: 1;
}

.infoIcon {
  display: inline-flex;
  margin-left: spacing(1);
  color: $c-9;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $c-6;
  }
}

.popover {
  width: 365px;
}
