@import "ui-library/app";

.popover {
  z-index: $zindex-popover;
}

.animation {
  transition: all 0.5s ease;
  transition-property: transform, opacity;
}

.animationFrom {
  transform: scale(0.5);
  opacity: 0;
}

.animationTo {
  transform: scale(1);
  opacity: 1;
}

.animationOpacityFrom {
  opacity: 0;
}

.animationOpacityTo {
  opacity: 1;
}

.wrapper {
  display: flex;
  width: 100%;
  background-color: $c-1;
  border-radius: 4px;
  box-shadow: $shadow;
}
