.root {
  grid-column: 1/-1;
}

.tabGroup {
  height: 100%;
}

.tabs {
  position: relative;
  z-index: 6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 140px;
}

.buttonGroup {
  position: absolute;
  top: 0;
  left: 0;
}

.filter {
  position: relative;
  z-index: 5;
  margin-top: -48px;
}
