@import 'ui-library/app';

$animation-duration: 1.5s;
$animation-delay: 0.5s;

.line {
  width: calc(var(--width, 100%) * 1px);
  height: calc(var(--height, 24) * 1px);
  background-color: $c-bg-light-grey;
  border-radius: calc(var(--border-radius, 2) * 1px);
  animation: shine $animation-duration ease-in-out $animation-delay infinite;
}

@keyframes shine {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}
