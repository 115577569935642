@import 'ui-library/app';

.root {
  position: relative;
  width: 100%;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: spacing(2);
}

.inputLayout {
  flex-grow: 1;
}

.inputBox {
  padding-left: spacing(1);
}

.combobox {
  position: absolute;
  top: calc(100% + 8px);
  left: -16px;
  z-index: 1000000;
  width: 558px;
  max-height: 280px;
  overflow: auto;
  background-color: $c-bg-inputs;
  border: 1px solid $c-bg-light-grey;
  border-radius: 4px;
}

.item {
  @include font-text-regular-16($c-bg-dark);
  padding: spacing(2);
  cursor: pointer;

  &:hover {
    background-color: $c-bg-secondary-hover;
  }
}

.itemHighlighted {
  background-color: $c-bg-secondary-hover;
}

.hidden {
  visibility: hidden;
}

.loader {
  display: flex;
  justify-content: center;
  padding: spacing(2);
}
