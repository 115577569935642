/* Соглашения об именовании
  1.Если начертаний шрифтов одного типа (h1, h2, text and etc) несколько,
    то указываем в имени тип начертания (medium, regular and etc) и размер шрифта.
    Пример, "font-h2-regular-24".
    В ином случае, лишних специфических данных не указываем.
*/

// #region DEPRECATED

$c-font-h1: $c-5;

@mixin font-h1($color: $c-font-h1) {
  color: $color;
  font-weight: 400;
  font-size: 32px;
  font-family: PTSerifCaption, sans-serif;
  line-height: 1.31;
}

$c-h2-regular-24: $c-5;

@mixin font-h2-regular-24($color: $c-h2-regular-24) {
  color: $color;
  font-weight: 400;
  font-size: 24px;
  font-family: PTSerifCaption, sans-serif;
  line-height: 1.33;
}

$c-h2-medium-24: $c-5;

@mixin font-h2-medium-24($color: $c-h2-medium-24) {
  color: $color;
  font-weight: 500;
  font-size: 24px;
  font-family: Commissioner, sans-serif;
  line-height: 1.33;
}

$c-font-h3: $c-5;

@mixin font-h3($color: $c-font-h3) {
  color: $color;
  font-weight: 500;
  font-size: 18px;
  font-family: Commissioner, sans-serif;
  line-height: 1.33;
}

$c-font-text-medium-18: $c-5;

@mixin font-text-medium-18($color: $c-font-text-medium-18) {
  color: $color;
  font-weight: 400;
  font-size: 18px;
  font-family: Commissioner, sans-serif;
  line-height: 1.44;
}

$c-font-text-semibold-16: $c-5;

@mixin font-text-semibold-16($color: $c-font-text-semibold-16) {
  color: $color;
  font-weight: 600;
  font-size: 16px;
  font-family: Commissioner, sans-serif;
  line-height: 1.5;
}

$c-font-text-regular-16: $c-5;

@mixin font-text-regular-16($color: $c-font-text-regular-16) {
  color: $color;
  font-weight: 400;
  font-size: 16px;
  font-family: Commissioner, sans-serif;
  line-height: 1.38;
}

$c-font-text-italic-16: $c-5;

@mixin font-text-italic-16($color: $c-font-text-italic-16) {
  color: $color;
  font-weight: 400;
  font-size: 16px;
  font-family: PTSerifCaptionItalic, sans-serif;
  line-height: 1.5;
}

$c-font-text-italic-14: $c-5;

@mixin font-text-italic-14($color: $c-font-text-italic-14) {
  color: $color;
  font-weight: 400;
  font-size: 14px;
  font-family: PTSerifCaptionItalic, sans-serif;
  line-height: 1.43;
}

$c-font-text-regular-14: $c-5;

@mixin font-text-regular-14($color:$c-font-text-regular-14) {
  color: $color;
  font-weight: 400;
  font-size: 14px;
  font-family: Commissioner, sans-serif;
  line-height: 1.43;
}

// #endregion

// #region REDESIGN

@mixin font-h1-regular($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 37px;
  font-family: NotoSerif, sans-serif;
  line-height: 48px;
}

@mixin font-h2-regular($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 28px;
  font-family: NotoSerif, sans-serif;
  line-height: 40px;
}

@mixin font-h4-regular($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 23px;
  font-family: NotoSerif, sans-serif;
  line-height: 32px;
}

@mixin font-h5-medium($color: $c-text-primary) {
  color: $color;
  font-weight: 500;
  font-size: 17px;
  font-family: NotoSerif, sans-serif;
  line-height: 26px;
}

@mixin font-h6-medium($color: $c-text-primary) {
  color: $color;
  font-weight: 500;
  font-size: 15px;
  font-family: NotoSerif, sans-serif;
  line-height: 24px;
}

@mixin font-b2-italic-regular($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 15px;
  font-family: NotoSerifItalic, sans-serif;
  line-height: 24px;
}

@mixin font-b1-regular-18($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 18px;
  font-family: OpenSans, sans-serif;
  line-height: 26px;
}

@mixin font-b2-semibold-16($color: $c-text-primary) {
  color: $color;
  font-weight: 600;
  font-size: 16px;
  font-family: OpenSans, sans-serif;
  line-height: 24px;
}

@mixin font-b2-regular-16($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 16px;
  font-family: OpenSans, sans-serif;
  line-height: 24px;
}

@mixin font-b2-medium-15($color: $c-text-primary) {
  color: $color;
  font-weight: 500;
  font-size: 15px;
  font-family: OpenSans, sans-serif;
  line-height: 24px;
}

@mixin font-b3-regular-14($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 14px;
  font-family: OpenSans, sans-serif;
  line-height: 20px;
}

@mixin font-b4-regular-11($color: $c-text-primary) {
  color: $color;
  font-weight: 400;
  font-size: 11px;
  font-family: OpenSans, sans-serif;
  line-height: 16px;
}

// #endregion
