@import 'ui-library/app';

.root {
  position: relative;
  flex: 1;
}

.label {
  margin-bottom: spacing(1);
}
