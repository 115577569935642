@import 'ui-library/app';

.index {
  position: absolute;
  display: block;
  min-width: 24px;
  height: 24px;
  padding: spacing(0, 0.5);
  text-align: center;
  background-color: $c-bg-secondary;
  border: 1px solid $c-bg-light-grey;
  border-radius: 4px;
  @include font-b3-regular-14;
}

.topLeft {
  top: spacing(1);
  left: spacing(1.5);
}

.rightBottom {
  right: spacing(2);
  bottom: spacing(2);
}

.static {
  position: static;
}
