@import 'ui-library/app';

.root {
  position: fixed;
  z-index: $zindex-modal;
  display: flex;
  inset: 0;
  margin: spacing(8, 12);
}

.backdrop {
  position: fixed;
  z-index: -1;
  inset: 0;
  background-color: $c-3;
}

.wrap {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.close {
  position: absolute;
  top: spacing(-4);
  right: spacing(-4);
  z-index: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.panel {
  width: 100%;
  height: 100%;
}
