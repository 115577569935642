@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(1.25);
  align-items: center;
}

.divider {
  display: block;
  width: 1px; 
  height: 13px;
  margin: spacing(1, 0);
  background-color: $c-bg-light-grey;
  border: 0;
} 

.button {
  @include clear-button;
  @include font-b3-regular-14($c-text-primary);
  transition: $transition;

  &.active {
    color: $c-text-accent;
  }

  &:hover {
    color: $c-text-secondary;
  }
}