@import 'ui-library/app';

.root {
  position: relative;
  flex: 1;
  overflow-x: hidden;
  text-overflow: ellipsis;
  overflow-wrap: anywhere;
}

.link {
  display: block;
  overflow-x: hidden;
  color: $c-text-accent;
  text-overflow: ellipsis;

  &:not(&:first-child) {
    margin-top: spacing(3);
  }
}
