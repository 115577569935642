@import 'ui-library/app';

.title {
  margin-bottom: spacing(2);
  padding-right: spacing(3.5);
  overflow-wrap: anywhere;
  @include font-h3($c-5);
  @include line-clamp(2);
}

.status {
  @include font-text-regular-14($c-6);
}

.caption {
  color: $c-5;
}

.captionIcon {
  color: $c-6;
}

.favorite {
  position: absolute;
  right: spacing(2);
  bottom: spacing(2);
  color: $c-primary;
  transition: $transition;
}

.root {
  justify-content: space-between;
  background-color: transparent;

  &:hover {
    background-color: $c-primary;

    .favorite {
      color: $c-1;
    }

    .title {
      color: $c-1;
    }

    .status {
      color: $c-1;
      opacity: 0.6;
    }

    .caption {
      color: $c-1;
    }

    .actions {
      visibility: visible;
      opacity: 1;
    }

    .captionIcon {
      color: $c-11;
    }
  }
}

.captionWrap {
  display: flex;
  gap: spacing(2);
}
