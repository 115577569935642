@import "ui-library/app";
@import "features/folders/styles/index";

$header-spacing-y: 3;

.container {
  position: sticky;
  top: -1px;
  display: flex;
  justify-content: space-between;
  padding-top: spacing($header-spacing-y);
  padding-bottom: spacing($header-spacing-y);
  background: $c-bg-primary;
  transition: $transition;
}

.header {
  display: flex;
}

.titleWrap {
  display: flex;
}

.sticky {
  z-index: $zindex-header;
  margin: spacing(0, -$protected-layout-spacing-x);
  padding: spacing($header-spacing-y, $protected-layout-spacing-x);
}

.icon {
  position: absolute;
  bottom: spacing(1.5);
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
}
