@import 'ui-library/app';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 400px;
  max-width: 558px;
  min-height: 40px;
  margin: 20px 0;
  padding: spacing(0, 2);
  background-color: $c-bg-inputs;
  border: 1px solid $c-bg-light-grey;
  border-radius: 8px;
}

.divider {
  position: relative;
  display: flex;
  height: 12px;
  border-left: 1px solid $c-bg-light-grey;
}

.searchBar {
  flex: 1;
  margin-left: spacing(-2);
}

.combobox {
  background-color: $c-bg-inputs;
  border: 1px solid $c-bg-light-grey;
}
