@import 'ui-library/app';

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 292px;
  margin: spacing(1, 0);
  word-break: break-all;
}

.avatar {
  width: 48px;
  height: 48px;
  margin-top: spacing(1);
  margin-bottom: spacing(1);
}

.username {
  margin: spacing(0, 1);
  text-align: center;
  @include font-b2-regular-16;
}

.email {
  margin: spacing(0.25) spacing(1) spacing(2.5);
  text-align: center;
  @include font-b3-regular-14;
}

.button {
  &:hover {
    color: $c-text-accent;
  }
}
