@import 'ui-library/app';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.title {
  margin-top: spacing(5);
  margin-bottom: spacing(3);
  @include font-h3($c-5);
}

.imageTitle {
  margin-top: spacing(5.75);
  margin-bottom: spacing(3);
  @include font-h3($c-5);
}

.panels,
.materialPanel {
  overflow-x: hidden;
}
