@import 'ui-library/app';

.century {
  flex-direction: column;

  @include l {
    flex-direction: row;
  }
}

.centuryHalf {
  @include to-l {
    margin-top: spacing(1.5);
    /* stylelint-disable-next-line declaration-no-important */
    margin-left: 0 !important;
  }
}
