@import "ui-library/app";

.root {
  display: flex;
  padding: spacing(0.5, 3);
  background-color: $c-1;
}

.zero {
  padding: spacing(8);
}
