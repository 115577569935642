@import 'ui-library/app';

.text {
  @include font-h5-medium($c-text-primary);
}

.image {
  width: 24px;
  height: 24px;
  border-radius: 1px;
}

.status {
  @include font-b3-regular-14($c-text-secondary);
}

.count {
  @include font-b3-regular-14($c-text-secondary);
}

.icon {
  width: 20px;
  height: 20px;
  color: $c-6;
}

.hover {
  opacity: 0;
  transition: $transition;
}

.hover:hover {
  opacity: 1;
}

.favorite {
  opacity: 1;
}

.hidden {
  opacity: 0;
}
