@import 'ui-library/app';

.root {
  position: relative;
}

.adornment {
  position: absolute;
  top: 50%;
  display: flex;
  color: $c-10;
  transform: translate(0, -50%);
}

.startAdornment {
  left: 0;
}

.endAdornment {
  right: 0;
}

.input {
  width: 100%;
  transition: $transition;
  transition-property: border-color, background-color;
}

.filled {
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: spacing(1) spacing(2);
  background-color: $c-12;
  border: 1px solid;
  border-color: transparent;
  border-radius: 4px;
  outline: none;

  &.disabled {
    cursor: default;

    span {
      color: $c-10;
    }
  }

  &:focus-within:not(.disabled),
  &:focus:not(.disabled),
  &:focus-visible:not(.disabled) {
    border-color: $c-10;
  }

  &:hover:not(.disabled) {
    background-color: $c-11;
  }

  &.error {
    border-color: $c-4;
  }

  & + .endAdornment {
    right: 16px;
  }

  &.adornedEnd {
    padding-right: 48px;
  }

  &.adornedStart {
    padding-left: 48px;
  }
}

.standard {
  padding: 12px 0;
  background-color: $c-1;
  border: none;
  border-bottom: 1px solid $c-10;

  &:hover:not(.disabled) {
    border-color: $c-6;
  }

  &.adornedEnd {
    padding-right: 40px;
  }

  &.adornedStart {
    padding-left: 40px;
  }
}
