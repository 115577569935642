@import "ui-library/app";
@import "features/folders/styles/index";

$header-spacing-y: 3;

.root {
  position: sticky;
  top: -1px;
  display: flex;
  justify-content: space-between;
  padding-top: spacing($header-spacing-y);
  padding-bottom: spacing($header-spacing-y);
  background: $c-bg-primary;
  transition: $transition;
}

.actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.sticky {
  z-index: $zindex-header;
  margin: spacing(0, -$protected-layout-spacing-x);
  padding: spacing($header-spacing-y, $protected-layout-spacing-x);
}

.hidden {
  display: none;
}
