@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: spacing(4);
  max-width: 100%;
  margin-top: spacing(8);

  @include l {
    max-width: 74%;
  }
}
