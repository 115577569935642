@import 'ui-library/app';

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  @include font-h1($c-5);

  overflow-wrap: anywhere;
}

.buttonGroup {
  display: flex;
}

.button {
  margin-right: spacing(2);
}
