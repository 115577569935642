@import 'ui-library/app';

.root {
  @include font-text-regular-16($c-5);

  display: flex;
  align-items: center;
  height: 54px;
  padding: 16px;
  cursor: pointer;
  transition: $transition;
  transition-property: background-color, color;
}

.checkboxField {
  & > * {
    margin-bottom: 0;
  }
}
