@import 'ui-library/app';

.root {
  position: relative;
}

.adornment {
  position: absolute;
  top: 50%;
  display: flex;
  align-items: center;
  color: $c-text-secondary;
  transform: translate(0, -50%);
}

.startAdornment {
  left: 0;
}

.endAdornment {
  right: 0;
}

.input {
  width: 100%;
  transition: $transition;
  transition-property: border-color, background-color;
}

.filled {
  display: flex;
  align-items: center;
  min-height: 60px;
  padding: spacing(1) spacing(3.5);
  outline: none;

  &.disabled {
    cursor: default;

    span {
      color: $c-text-secondary;
    }
  }

  &:focus-within:not(.disabled),
  &:focus:not(.disabled),
  &:focus-visible:not(.disabled) {
    border-color: $c-bg-light-grey;
  }

  &:hover:not(.disabled) {
    border-color: $c-bg-light-grey;
  }

  &.error {
    border-color: $c-main-error;
  }

  & + .endAdornment {
    right: 16px;
  }

  &.adornedEnd {
    padding-right: 48px;
  }

  &.adornedStart {
    padding-left: 48px;
  }
}

.standard {
  padding: 12px 0;
  background-color: $c-bg-inputs;
  border: none;
  border-bottom: 1px solid $c-bg-light-grey;

  &:hover:not(.disabled) {
    border-color: $c-bg-light-grey;
  }

  &.adornedEnd {
    padding-right: 40px;
  }

  &.adornedStart {
    padding-left: 40px;
  }
}
