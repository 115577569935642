@import 'ui-library/app';

$border-radius: 50%;

.root {
  position: relative;
  width: 22px;
  height: 22px;
  cursor: pointer;
}

.circle {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border: 2px solid $c-primary;
  border-radius: $border-radius;
}

.check {
  position: absolute;
  width: 12px;
  height: 12px;
  background-color: $c-primary;
  border-radius: $border-radius;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  transition-property: opacity;
}

.input {
  position: absolute;
  z-index: 4;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  opacity: 0;

  &:hover:not(:checked) + .circle {
    background-color: $c-12;
  }

  &:checked {
    & + .circle .check {
      visibility: visible;
      opacity: 1;
    }

    &:hover {
      & + .circle .check {
        background-color: $c-primary-hover;
      }
    }
  }
}
