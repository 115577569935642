@import 'ui-library/app';

.checkbox {
  &:checked {
    visibility: visible;
    opacity: 1;
  }

  position: absolute;
  top: spacing(1);
  left: spacing(1);
  justify-content: flex-end;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
}

.root {
  position: relative;
  display: flex;
  flex: 1;

  & > div[data-viewport-type='window'] {
    position: relative !important; // чтобы sticky footer был доступен на всей высоте блока
  }
}

.imageGridItem {
  position: relative;

  &:hover {
    .checkbox {
      visibility: visible;
      opacity: 1;
    }
  }

  &.active {
    .checkbox {
      visibility: visible;
      opacity: 1;
    }
  }
}
