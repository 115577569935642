@import 'ui-library/app';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.loading {
  padding: spacing(16, 0);
}
