@import 'ui-library/app';

.card {
  justify-content: flex-end;
  background-color: $c-bg-light-grey;

  @include m {
    height: 157px;
  }

  @include l {
    height: 232px;
  }

  @include xl {
    height: 318px;
  }
}

.secondRow {
  margin-top: spacing(1.5);
}
