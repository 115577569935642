@import 'ui-library/app';

.root {
  @include font-text-regular-16($c-5);

  display: flex;
  align-items: center;
  min-height: 54px;
  padding: 16px;
  cursor: pointer;
  transition: $transition;
  transition-property: background-color, color;

  &:hover,
  &.active {
    background-color: $c-bg-secondary-hover;
  }
}

.selected {
  color: $c-1;
  background-color: $c-primary;

  &:hover,
  &.active {
    background-color: $c-primary-hover;
  }
}
