@import 'ui-library/app';

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.favorite {
  position: absolute;
  right: spacing(2);
  bottom: spacing(2);
  color: $c-primary;
  transition: $transition;
}

.actions {
  top: spacing(0);
  right: spacing(1);
}

.root {
  &:hover {
    background-color: rgba($c-text-primary, 0.5);

    .favorite {
      color: $c-1;
    }

    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}

