@import 'ui-library/app';

.root {
  display: flex;
}

.title {
  flex: 1;
  @include font-text-italic-16($c-6);
}

.value {
  flex: 1;
  overflow-wrap: anywhere;
  @include font-text-regular-16($c-5);
}

.hidden {
  display: none;
}
