@import "ui-library/app";

.root {
  position: relative;
  display: flex;
  flex: 1;
  overflow: hidden;
}

.list {
  flex: 1;
}

.placeholder {
  margin-top: spacing(8);
}
