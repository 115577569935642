@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(2);
  align-items: center;
  justify-content: space-between;
  padding: spacing(2, 3);
  background-color: $c-11;
}

.title {
  @include font-text-regular-14($c-5);
}

.actions {
  display: flex;
  gap: spacing(0.5);
}

.opened {
  transform: rotate(90deg);
}

.collapsed {
  transform: rotate(-90deg);
}

.button {
  color: $c-6;
  transition: $transition;
  transition-property: color, transform;

  &:hover {
    color: $c-5;
  }
}
