@import 'ui-library/app';

.root {
  position: relative;
  display: flex;
  align-items: center;
  height: 78px;
  padding-top: spacing(2);
  background-color: transparent;
}

.logo {
  position: absolute;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
}

.icon {
  width: 252px;
  height: 58px;
}

.menu {
  margin-left: spacing(1.5);
}

.language {
  margin-left: auto;
}
