@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: $c-1;
}

.info {
  margin-top: spacing(11);
  padding: spacing(3, 0, 3, 7);

  @include l {
    padding: spacing(3, 0, 3, 8);
  }

  @include xl {
    padding: spacing(3, 0, 3, 10);
  }
}
