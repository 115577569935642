@import 'ui-library/app';

.root {
  position: fixed;
  z-index: -1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $backdrop;
  inset: 0;
  -webkit-tap-highlight-color: transparent;
}

.animation {
  transition: $transition;
  transition-property: opacity;
}

.from {
  opacity: 0;
}

.to {
  opacity: 1;
}
