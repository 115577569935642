@import 'ui-library/app';

.spinner {
  position: relative;
  display: inline-block;
  border: 2px solid;
  border-top-color: transparent;
  border-radius: 50%;
  animation: rotate 1s linear infinite;
}

.small {
  width: 18px;
  height: 18px;
}

.medium {
  width: 24px;
  height: 24px;
}

.large {
  width: 48px;
  height: 48px;
}

.main {
  color: $c-text-tertiary;
}

.secondary {
  color: $c-btn-primary-line;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}
