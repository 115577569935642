@import 'ui-library/app';

.listWrap {
  position: relative;
  display: flex;
  flex: 1;
  margin-bottom: spacing(1);
  overflow: hidden;
}

.list {
  flex: 1;
}

.placeholder {
  margin-top: spacing(8);
}