@import 'ui-library/app';

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 796px;
  min-height: 60px;
  margin: 0 auto;
  padding: spacing(0, 2);
  background-color: $c-bg-inputs;
  border: 1px solid $c-bg-light-grey;
  border-radius: 30px;
}

.divider {
  position: relative;
  display: flex;
  height: 12px;
  border-left: 1px solid $c-bg-light-grey;
}

.searchBar {
  flex: 1;
  margin-left: spacing(-2);
}

.combobox {
  background-color: $c-bg-inputs;
  border: 1px solid $c-bg-light-grey;
}
