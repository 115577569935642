@import 'ui-library/app';

.root {
  padding-bottom: spacing(3);
  color: $c-1;
  background-color: $c-8;
}

.header {
  display: flex;
  gap: spacing(2);
  align-items: center;
  margin-bottom: spacing(1.5);
}

.title {
  @include line-clamp(2);
  @include font-h2-medium-24($c-1);
}

.previewIcon {
  display: flex;
  align-items: center;
  width: 48px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
}
