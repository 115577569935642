@import 'ui-library/app';

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.title {
  @include font-h1($c-5);
  margin-right: spacing(2);
}

.buttonGroup {
  display: flex;
}

.actionsMenu {
  min-width: 250px;
}

.button {
  margin-right: spacing(2);
}
