@import 'ui-library/app';

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: spacing(2);

}

.spinner {
  color: $c-8;
}
