@import "ui-library/app";

$sidebar-width-m: 260px;
$sidebar-width-l: 340px;
$sidebar-width-xl: 420px;
$sidebar-transition: $transition;

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $zindex-sidebar;
  display: flex;
  flex-direction: column;
  width: $sidebar-width-m;
  height: 100%;
  padding: spacing(3, 2, 0, 2);
  background-color: $c-8;
  transition: $sidebar-transition;

  &.persistent + main {
    margin-left: calc($sidebar-width-m + spacing($protected-layout-spacing-x-m));

    @include l {
      margin-left: calc($sidebar-width-l + spacing($protected-layout-spacing-x-l));
    }

    @include xl {
      margin-left: calc($sidebar-width-xl + spacing($protected-layout-spacing-x-xl));
    }
  }

  @include l {
    width: $sidebar-width-l;
  }

  @include xl {
    width: $sidebar-width-xl;
  }
}

.menu {
  position: fixed;
  top: spacing(2);
  left: spacing(2);
  z-index: 10;
  opacity: 1;
  transition: $sidebar-transition;
}

.menuHidden {
  visibility: hidden;
  opacity: 0;
}

.sidebarHidden {
  visibility: hidden;

  &.persistent {
    margin-left: -$sidebar-width-m;

    & + main {
      margin-left: spacing(11);
    }

    @include l {
      margin-left: -$sidebar-width-l;
    }

    @include xl {
      margin-left: -$sidebar-width-xl;
    }
  }

  &.temporary {
    transform: translateX(-$sidebar-width-m);

    @include l {
      transform: translateX(-$sidebar-width-l);
    }

    @include xl {
      transform: translateX(-$sidebar-width-xl);
    }
  }
}

.logoContainer {
  display: flex;
  margin-left: spacing(2);
}

.logoText {
  @include font-text-regular-16($c-1);

  @include l {
    @include font-text-medium-18($c-1);
  }
}

.links {
  margin: spacing(2.5, 0);
}

.themes {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.toggler {
  margin-top: spacing(2);
}