@import "ui-library/app";

.icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  border-radius: 1px;
}

.primary {
  @include font-h3(inherit);
  @include line-clamp(3);
}

.chevron {
  margin-left: auto;
}
