@import 'ui-library/app';

.root {
  margin-top: spacing(12);
}

.tabs {
  margin-top: spacing(5);
}

.childModalOpened {
  visibility: hidden;
  opacity: 0;
}

