@import 'ui-library/app';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: spacing(6.5);
}

.instructionListWrap {
  margin-bottom: spacing(2);
}
