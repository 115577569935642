@import 'ui-library/app';

.favoriteButton {
  @include size(24px);
}

.notFavoriteButton {
  @include size(24px);
}

.favorite {
  color: $c-btn-primary;;
}

.notFavorite {
  color: $c-text-secondary;
  opacity: 0.4;
}
