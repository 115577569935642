@import 'ui-library/app';

.root {
  display: inline-flex;
  gap: spacing(1);
  cursor: pointer;
}

.arrow {
  display: flex;
}
