@import 'ui-library/app';

.root {
  display: flex;
  flex-direction: column;
}

.title {
  @include font-text-semibold-16($c-5);
}

.list {
  margin-top: spacing(2);
}

.item {
  display: flex;
  align-items: center;
  @include font-text-regular-16($c-6);

  & + & {
    margin-top: spacing(1);
  }

  svg {
    margin-right: spacing(1);
    color: $c-10;
  }

  &.passed {
    color: $c-5;

    svg {
      color: $c-13;
    }
  }
}
