@import "ui-library/app";

.root {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  padding: spacing(3);
  border-color: $c-11;
  border-radius: 8px;

  &:hover {
    background-color: transparent;
  }
}

.title {
  white-space: pre-wrap;
  @include font-text-semibold-16($c-5);
}

.subtitle {
  white-space: pre-wrap;
  @include font-text-regular-14($c-6);
}

.icon {
  svg {
    width: 48px;
    height: 48px;
  }
}
