@import 'ui-library/app';

.button {
  position: absolute;
  top: spacing(0.5);
  right: spacing(1.5);
  color: $c-1;
  visibility: hidden;
  opacity: 0;
  transition: $transition;

  &:hover {
    color: $c-text-accent;
  }
}

.wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
