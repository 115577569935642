@import "ui-library/app";

$accordion-transition: all 0.2s;

.label {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;

  @include font-h3($c-5);
}

.icon {
  margin-left: spacing(3);
  transform: scaleY(1);
  transition: $accordion-transition;
}

.iconClosed {
  transform: scaleY(-1);
}

.content {
  max-height: 0;
  background-color: $c-1;
  transform: scaleY(0.9);
  visibility: hidden;
  opacity: 0;
  transition: $accordion-transition;
}

.contentOpened {
  max-height: none;
  padding-top: spacing(3);
  transform: scaleY(1);
  visibility: visible;
  opacity: 1;
}

.header {
  display: flex;
  justify-content: space-between;
}
