@import 'ui-library/app';

.root {
  display: flex;
  align-items: center;
}

.label {
  @include font-text-regular-16($c-5);

  padding-left: spacing(2);
  cursor: pointer;
}
