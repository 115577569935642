@import 'ui-library/app';

.skeleton {
  margin-bottom: spacing(3);
  break-inside: avoid;
}

.skeleton1 {
  height: 200px;
}

.skeleton2 {
  height: 250px;
}

.skeleton3 {
  height: 150px;
}
