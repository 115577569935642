$spacing: 8px;
$transition: all 0.3s ease;

/* Protected Layout Spacings */

// DEPRECATED
$protected-layout-spacing-x-xl: 10;
$protected-layout-spacing-x-l: 8;
$protected-layout-spacing-x-m: 7;
$protected-layout-spacing-y: 2;

// REDESIGN
$protected-layout-spacing-x: 5;
$protected-layout-spacing-y-redesign: 0;

/* z-indexes */
$zindex-dropdown: 1300;
$zindex-modal: 1500;
$zindex-select-options: 1700;
$zindex-popover: 1800;
$zindex-sidebar: 1800;
$zindex-header: 1900;
