@import 'ui-library/app';

.root {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: spacing(2);
  row-gap: spacing(2);
}

.image {
  z-index: 1;
}

