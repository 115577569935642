@import "ui-library/app";

.icon {
    display: flex;
    color: $c-text-secondary;
    border-radius: 1px;
}

.icon-start {
    align-self: flex-start;
}

.icon-center {
    align-self: center;
}

.icon-end {
    align-self: flex-end;
}
