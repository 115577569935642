@import "ui-library/app";

.wrap {
  position: relative;
}

.userInfo {
  display: flex;
  align-items: center;
  cursor: pointer;

  picture {
    display: flex;
  }

  @include font-text-medium-18($c-5);
}

.avatar {
  width: 32px;
  height: 32px;
  margin-right: spacing(2);
}
