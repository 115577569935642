.preview {
  position: absolute;
  visibility: hidden;
  opacity: 0;

  img {
    max-width: 132px;
    max-height: 88px;
  }
}

.ready {
  position: static;
  visibility: visible;
  opacity: 1;
}
