@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(4);
}

.active {
  color: $c-5;
}
