@import 'ui-library/app';

.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.detailInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: spacing(21, 34, 0, 34);
}
