@import "ui-library/app";

.wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $c-1;

    @include grid-item-start($xs: 4, $l: 5, $xl: 6);
    @include grid-item-end($xs: 7, $l: 8, $xl: 9);

    @include xs {
        margin: spacing(7, 0);
        padding: spacing(3);
    }

    @include l {
        margin: spacing(8, 0);
        padding: spacing(4);
    }

    @include xl {
        margin: spacing(10, 0);
    }
}

.languageToggler {
    position: absolute;
    top: spacing(1.5);
    right: spacing(1.5);

    @include xl {
        top: spacing(2.5);
        right: spacing(2.5);
    }
}

.title {
    @include font-h2-regular-24($c-5);
    white-space: pre-wrap;
    @include l {
        @include font-h1($c-5);
    }
}

.subtitle {
    margin-top: spacing(2);
    @include font-text-italic-16($c-5);
}

.formContainer {
    padding: spacing(0, 4);

    @include xl {
        padding: spacing(0, 7);
    }
}

.formWrapper {
    margin-top: spacing(3);

    @include l {
        margin-top: spacing(4);
    }
}
