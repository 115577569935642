@import 'ui-library/app';

.root {
  display: flex;

  @include clear-button;
  @include font-text-medium-18($c-6);

  padding-bottom: spacing(1);
  border-bottom: 2px solid transparent;

  &:focus-within {
    outline: none;
  }
}

.selected {
  color: $c-5;
  border-bottom: 2px solid $c-5;
}
