@import 'ui-library/app';

$removed-background-color: rgba(#d2d5d9, 0.5);

.root {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: $c-12;
  border-radius: 4px;
}

.extended {
  &.root {
    background-color: $c-9;
  }
}

.focused {
  &.root {
    background-color: $c-10;
  }
}

.removed {
  &.root {
    background-color: $removed-background-color;
  }
}

.menu {
  min-width: 300px;
}
