@import "ui-library/app";

.root {
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.breadcrumbs {
  margin: spacing(3, 0);
}

.description {
  margin-top: spacing(2);
  margin-bottom: spacing(2);
}

.info {
  margin-bottom: spacing(2);
}
