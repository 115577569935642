@import 'ui-library/app';

.root {
  display: flex;
  gap: spacing(2);
  margin-bottom: spacing(2);
  padding-bottom: spacing(3);
  color: $c-1;
  background-color: $c-8;
}

.title {
  @include line-clamp(2);
  @include font-h2-medium-24($c-1);
}

.layoutIcon {
  width: 48px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
}

.imageIcon {
  width: 48px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
}

.folderIcon {
  width: 32px;
  height: 32px;
  object-fit: contain;
  border-radius: 4px;
}
