@import 'ui-library/app';

.breadcrumb {
  direction: ltr;
  unicode-bidi: embed;
  cursor: pointer;
}

.divider {
  color: $c-10;
}

.folderDivider {
  margin: spacing(0, 0.5);

  svg {
    width: 16px;
    height: 16px;
  }

  transform: scale(1.25) translateY(2px);
}

.folderBreadcrumbs {
  overflow: hidden;
  direction: rtl;
  white-space: nowrap;
  text-align: left;
  text-overflow: ellipsis;
}

.root {
  display: flex;
  align-items: center;
  min-height: 24px;
  @include font-text-regular-14($c-6);

  & .breadcrumb:last-child {
    color: $c-1;
  }
}

.backButton {
  margin-right: spacing(1);
  color: $c-1;
}
