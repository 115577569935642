@import 'ui-library/app';

.root {
  position: relative;
}

.title {
  padding: 0 260px; // FolderDetailButtonGroup width
  text-align: center;
  @include font-h1($c-5);
}

.buttonGroup {
  position: absolute;
  top: 0;
  right: 0;
}
