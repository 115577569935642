@import 'ui-library/app';

.inputWrap {
  margin: spacing(0, 2, 1, 2);
}

.inputControl {
  color: $c-1;

  &::placeholder {
    color: $c-10;
  }
}

.input {
  background-color: transparent;
  border-color: $c-9;

  &:hover:not(&:disabled) {
    border-color: $c-10;
  }
}

.error {
  margin-top: spacing(8);
}
