@import 'ui-library/app';
@import '../BaseSelect/selectOptions';

.button {
  cursor: pointer;
}

.root {
  position: relative;
}

.placeholder {
  color: $c-6;
}

.options {
  @include select-options;
}

.loader {
  display: flex;
  justify-content: center;
  padding: 10px;
}

.noOptionsMessage {
  @include font-text-regular-16($c-5);

  padding-top: spacing(9);
  padding-bottom: spacing(9);
  overflow: hidden;
  text-align: center;
}
