@import 'ui-library/app';

.animation {
  transition: $transition;
  transition-property: opacity, transform;
}

.animationSlideFrom {
  transform: translateX(100%);
}

.animationSlideTo {
  transform: translateX(0);
}

.animationOpacityFrom {
  opacity: 0;
}

.animationOpacityTo {
  opacity: 1;
}

.popupPanel {
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - spacing(8));
  overflow-y: auto;
  color: $c-5;
  background-color: $c-1;
  border-radius: 4px;
  outline: 0;
  transform: translate(-50%, -50%);

  --vertical-padding: #{spacing(8)};

  @include m {
    width: 56vw;
  }

  @include l {
    width: 48vw;
  }

  @include xl {
    width: 41vw;
  }
}

.closeButton {
  position: absolute;
  top: spacing(2);
  right: spacing(2);
  color: $c-5;
}
