@import 'ui-library/app';

.button {
  cursor: pointer;

  @include font-b2-regular-16($c-text-primary);

  &.active {
    border-color: $c-bg-inputs;
  }

  &.placeholder {
    color: $c-text-primary;
  }
}

.value {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.endAdornment {
  z-index: 1;
  cursor: pointer;
}
