@import 'ui-library/app';

.button {
  @include clear-button;
  @include font-b3-regular-14;

  padding: spacing(1);
  text-transform: capitalize;
  transition: $transition;

  &:hover {
    color: $c-text-accent;
  }
}

.themeLight {
  color: $c-1;

  &:hover {
    color: $c-1;
  }
}

.action {
  width: 219px;
}
