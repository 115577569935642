@import "ui-library/app";

$folder-card-border-radius: 4px;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 157px;
  color: $c-1;
  border-radius: $folder-card-border-radius;
  transition: $transition;

  @include l {
    height: 232px;
  }

  @include xl {
    height: 318px;
  }
}
