html,
body {
  min-height: 100vh;
}

html {
  box-sizing: border-box;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  min-width: $breakpoint-m;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
}

li {
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;

  // Preserves extra height of anchor container
  & > * {
    vertical-align: bottom;
  }
}

address {
  font-style: normal;
}

fieldset {
  border: 0;
}
