@import 'ui-library/app';

.root {
  position: fixed;
  right: spacing(4);
  bottom: spacing(4);
  z-index: 1;
  padding: 0;

  svg {
    width: 40px;
    height: 40px;
  }
}

.anchorBottom {
  position: fixed;
  right: spacing(4);
  bottom: spacing(17);
  z-index: 1;
  padding: 0;

  svg {
    width: 40px;
    height: 40px;
  }
}

.downloadModalCollapsed {
  bottom: spacing(8);
}

.downloadModalOpened {
  visibility: hidden;
}
