@import "ui-library/app";

.root {
  overflow-y: hidden;
}

.button {
  margin-top: spacing(6);
  margin-bottom: spacing(5);
  padding: spacing(1.5, 8);
}
