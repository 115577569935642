@import "ui-library/app";

$padding-y-m: 10;
$padding-y-l: 10;
$padding-y-xl: 10;
$padding-m: 2;
$padding-l: 2;
$padding-xl: 3;

.root {
  position: relative;
  display: flex;
  flex: 0.7;
  padding: spacing($padding-y-m, $padding-m);
  background-color: $c-3;

  @include l {
    flex: 1;
    padding: spacing($padding-y-l, $padding-l);
  }

  @include xl {
    flex: 1.3;
    padding: spacing($padding-y-xl, $padding-xl);
  }
}

.buttonGroup {
  position: absolute;
  top: spacing(2);
  right: spacing($padding-m);

  @include l {
    right: spacing($padding-l);
  }

  @include xl {
    right: spacing($padding-xl);
  }
}

.image {
  width: 100%;
  height: calc(100vh - 2 * spacing($padding-y-m));
  object-fit: contain;

  @include l {
    height: calc(100vh - 2 * spacing($padding-y-l));
  }

  @include xl {
    height: calc(100vh - 2 * spacing($padding-y-xl));
  }
}
