@import 'ui-library/app';

.caption {
  transition: $transition;

  @include font-b2-regular-16($c-text-primary);
}

.root {
  position: relative;
  display: flex;
  gap: spacing(3);

  &.viewSearch {
    .caption {
      color: $c-5;
    }
  }

  &:hover {
    .caption {
      color: $c-text-accent;
    }
  }
}

.image {
  position: relative;
  object-fit: contain;
}

.info {
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  justify-content: center;
}
