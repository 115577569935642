@import 'ui-library/app';

.root {
  columns: 3;
  column-gap: spacing(3);
  column-width: 0;

  @include l {
    columns: 4;
  }

  @include xl {
    columns: 5;
  }
}
