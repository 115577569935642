@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.listWrap {
  flex: 1;
  margin-bottom: spacing(4);
}
