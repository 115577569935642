@import "ui-library/app";

.link {
  padding: 0;
  text-decoration: underline;
  @include font-text-regular-16($c-text-accent);
}

.button {
  margin-top: spacing(2);
}
