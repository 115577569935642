@import 'ui-library/app';

.paginationWrap {
  display: flex;
  justify-content: center;
  margin: spacing(4, 0);
}

.listWrap {
  flex: 1;
}

.root {
  z-index: 1;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.error {
  margin-top: spacing(8);
}

.button {
  left: 50%;
  width: 200px;
  margin-top: spacing(7);
  transform: translate(-50%, -50%)
}
