@import "ui-library/app";

.text {
  margin-bottom: spacing(2.75);
}

.buttonGroup {
  display: flex;
  gap: spacing(2);
  width: 100%;
}

.root {
  display: flex;
  flex-wrap: wrap;
}


