@import 'ui-library/app';

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  transition: $transition;

  &:not(&:disabled) {
    cursor: pointer;
  }
}

.normal {
  padding: 12px 20px;
  @include font-b1-regular-18;
}

.small {
  padding: 8px 20px;
  @include font-b2-regular-16;
}

.main {
  color: $c-text-tertiary;
  background-color: $c-btn-primary;

  &:not(.loading):disabled {
    background-color: $c-btn-primary-disabled;
  }

  &:hover:not(&:disabled) {
    background-color: $c-btn-primary-hover;
  }
}

.secondary {
  background-color: transparent;

  &.theme-default {
    color: $c-btn-primary-hover;
    border: 1px solid $c-btn-primary-line;

    &:not(.loading):disabled {
      color: $c-btn-primary-disabled;
      border-color: $c-btn-primary-disabled;
    }

    &:hover:not(&:disabled) {
      color: $c-btn-primary-line-hover;
      border-color: $c-btn-primary-line-hover;
    }
  }

  &.theme-secondary {
    color: $c-btn-primary;
    border: 1px solid $c-btn-primary-line;

    &:hover:not(&:disabled) {
      border-color: $c-btn-primary-line-hover;
    }
  }
}

.transparent {
  color: $c-btn-primary-line;
  background-color: transparent;
  border: none;

  &:hover {
    color: $c-btn-primary-line-hover;
  }
}
