@import 'ui-library/app';

.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 4px;
  transition: $transition;

  &:not(&:disabled) {
    cursor: pointer;
  }
}

.normal {
  padding: 12px 20px;
  @include font-h3;
}

.small {
  padding: 8px 20px;
  @include font-text-regular-16;
}

.main {
  color: $c-1;
  background-color: $c-primary;

  &:not(.loading):disabled {
    background-color: $c-10;
  }

  &:hover:not(&:disabled) {
    background-color: $c-primary-hover;
  }
}

.secondary {
  &.theme-default {
    color: $c-primary;
    background-color: $c-1;
    border: 1px solid $c-primary;

    &:not(.loading):disabled {
      color: $c-10;
      border-color: $c-10;
    }

    &:hover:not(&:disabled) {
      color: $c-primary-hover;
      border-color: $c-primary-hover;
    }
  }

  &.theme-secondary {
    color: $c-1;
    background-color: transparent;
    border: 1px solid $c-9;

    &:hover:not(&:disabled) {
      border-color: $c-10;
    }
  }
}

.transparent {
  color: $c-5;
  background-color: transparent;
  border: none;

  &:hover {
    color: $c-6;
  }
}
