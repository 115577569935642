@import "ui-library/app";

.listWrap {
  flex: 1;
}

.paginationWrap {
  display: flex;
  justify-content: center;
  margin: spacing(4, 0);
}
