@import "ui-library/app";

.container {
    flex: 1;
    border-radius: 4px;
    cursor: pointer;
    transition: $transition;

    @include font-text-regular-14($c-5);

    @include l {
        @include font-text-regular-16($c-5);
    }
}

.normal {
    padding: spacing(2);
}

.none {
    padding: 0;
}

.main {
    background-color: $c-1;

    &:hover {
        color: $c-1;
        background-color: $c-primary;
    }
}

.outlined {
    border: 1px solid $c-10;

    &:hover {
        color: $c-1;
        background-color: $c-primary;
        border-color: $c-primary;;
    }
}

.transparent {
    background-color: transparent;
}
