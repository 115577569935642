@import 'ui-library/app';
@import '../../styles/folderTable';

.body {
  @include folder-table;
}

.table {
  margin-bottom: spacing(3);
}

.row {
  background-color: $c-bg-primary;
}

.row:hover {
  background-color: $c-bg-primary-hover;
}

.actionGuest {
  visibility: hidden;
}
