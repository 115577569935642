@import "ui-library/app";

.formCol {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-width: 0;

    &:not(&:first-child) {
        margin-left: spacing(3);
    }
}
