@import 'ui-library/app';

.actions {
  top: spacing(7);
  right: spacing(1);
}

.actionGuest {
  visibility: hidden;
}

.author {
  position: absolute;
  bottom: spacing(1.5);
  left: spacing(1.5);
  visibility: hidden;
  opacity: 0;
  @include line-clamp(2);
  @include font-b3-regular-14($c-text-tertiary);
}

.root {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: rgb(53 50 49 / 35%);

    .favorite,
    .author,
    .actions {
      visibility: visible;
      opacity: 1;
    }
  }

  &.active {
    background-color: rgb(135 104 47 / 20%);

    &:hover {
      background-color: rgb(135 104 47 / 35%);

      .favorite,
      .author,
      .actions {
        visibility: hidden;
        opacity: 0;
      }
    }
  }
}

.image {
  z-index: -1;
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
