@import 'ui-library/app';

.root {
  display: flex;
  gap: spacing(3);
  align-items: flex-start;
}

.vertical {
  flex-direction: column;
  gap: spacing(1);
  align-items: flex-start;
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  touch-action: manipulation;
}

.description {
  @include font-text-regular-14($c-6);

  flex: 1;
  white-space: pre-wrap;
}
