@import 'ui-library/app';

.root {
  display: flex;
  align-items: center;
}

.buttonGroup {
  display: flex;
  gap: spacing(2);
  align-items: center;
  margin-right: spacing(2);
}

.actionsMenu {
  min-width: 250px;
}
