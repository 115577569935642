/*
  COMMISSIONER
*/

@font-face {
  font-weight: normal;
  font-family: Commissioner;
  font-style: normal;
  src: url('../fonts/Commissioner/Commissioner-Regular.woff2') format('woff2'),
  url('../fonts/Commissioner/Commissioner-Regular.woff') format('woff'),
  url('../fonts/Commissioner/Commissioner-Regular.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: Commissioner;
  font-style: normal;
  src: url('../fonts/Commissioner/Commissioner-SemiBold.woff2') format('woff2'),
  url('../fonts/Commissioner/Commissioner-SemiBold.woff') format('woff'),
  url('../fonts/Commissioner/Commissioner-SemiBold.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: Commissioner;
  font-style: normal;
  src: url('../fonts/Commissioner/Commissioner-Medium.woff2') format('woff2'),
  url('../fonts/Commissioner/Commissioner-Medium.woff') format('woff'),
  url('../fonts/Commissioner/Commissioner-Medium.ttf') format('truetype');
}

/*
  PT SERIF CAPTION
*/

@font-face {
  font-weight: normal;
  font-family: PTSerifCaption;
  font-style: normal;
  src: url('../fonts/PTSerif-Caption/PTSerif-Caption.woff') format('woff'),
  url('../fonts/PTSerif-Caption/PTSerif-Caption.woff2') format('woff2'),
  url('../fonts/PTSerif-Caption/PTSerif-Caption.ttf') format('truetype');
}

@font-face {
  font-weight: normal;
  font-family: PTSerifCaptionItalic;
  font-style: normal;
  src: url('../fonts/PTSerif-Caption/PTSerif-CaptionItalic.woff') format('woff'),
  url('../fonts/PTSerif-Caption/PTSerif-CaptionItalic.woff2') format('woff2'),
  url('../fonts/PTSerif-Caption/PTSerif-CaptionItalic.ttf') format('truetype');
}

/*
  OpenSans
*/

@font-face {
  font-weight: normal;
  font-family: OpenSans;
  font-style: normal;
  src: url('../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff');
}

@font-face {
  font-weight: 600;
  font-family: OpenSans;
  font-style: normal;
  src: url('../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
}

@font-face {
  font-weight: 500;
  font-family: OpenSans;
  font-style: normal;
  src: url('../fonts/OpenSans/OpenSans-Medium.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Medium.woff') format('woff');
}

/*
  NotoSerif
*/

@font-face {
  font-weight: normal;
  font-family: NotoSerif;
  font-style: normal;
  src: url('../fonts/NotoSerif/notoserif.woff2') format('woff2'),
  url('../fonts/NotoSerif/notoserif.woff') format('woff');
}

@font-face {
  font-weight: normal;
  font-family: NotoSerifItalic;
  font-style: normal;
  src: url('../fonts/NotoSerif/notoserifitalic.woff2') format('woff2'),
  url('../fonts/NotoSerif/notoserifitalic.woff') format('woff');
}
