@import "ui-library/app";
@import "features/folders/styles/index";

$header-spacing-y: 3;

.root {
  position: sticky;
  top: -1px;
  padding-top: spacing($header-spacing-y);
  padding-bottom: spacing($header-spacing-y);
  background: $c-1;
  background: $c-bg-primary;
  transition: $transition;
}

.sticky {
  z-index: $zindex-header;
  margin: spacing(0, -$protected-layout-spacing-x);
  padding: spacing($header-spacing-y, $protected-layout-spacing-x);
}

.title {
  position: absolute;
  left: 50%;
  margin: 0 auto;
  transform: translateX(-50%);
  @include font-h2-regular-24($c-5);
}

.tab {
  visibility: hidden;
}

.icon {
  position: absolute;
}
