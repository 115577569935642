@import "ui-library/app";

.root {
  position: relative;
  min-height: 100vh;
}

.main {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: spacing($protected-layout-spacing-y, $protected-layout-spacing-x-m);
  transition: $transition;

  @include l {
    margin: spacing($protected-layout-spacing-y, $protected-layout-spacing-x-l);
  }

  @include xl {
    margin: spacing($protected-layout-spacing-y, $protected-layout-spacing-x-xl);
  }
}
