@import 'ui-library/app';

.root {
  @include font-b2-regular-16($c-text-primary);

  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 16px;
  cursor: pointer;
  transition: $transition;
  transition-property: background-color, color;

  &:hover {
    background-color: $c-bg-secondary-hover;
  }
}

.icon {
  position: absolute;
  right: spacing(2);
  color: $c-text-secondary;
  visibility: hidden;
}

.selected {
  color: $c-bg-dark;

  .icon {
    visibility: visible;
  }

  &:hover {
    background-color: $c-bg-secondary-hover;
  }
}
