@import 'ui-library/app';

.root {
  display: flex;
  flex-direction: column;
  gap: spacing(6.5);
  min-height: 100vh;
  padding: spacing(0, 5);
  background-color: $c-bg-primary;
}

.container {
  z-index: 1;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: spacing(3);

  @include l {
    grid-template-columns: repeat(6, 1fr);
  }

  @include xl {
    grid-template-columns: repeat(7, 1fr);
  }
}
