@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.inputWrap {
  margin-bottom: spacing(2);
}
