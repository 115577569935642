@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(1);
}

.button {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  cursor: pointer;
}

.link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.page {
  @include font-b3-regular-14;
}

.active {
  background-color: $c-bg-primary-pressed;
}

.navigate {
  color: $c-text-primary;
}

.hidden {
  display: none;
}