@import 'ui-library/app';

.root {
  &.active {
    border-color: $c-6;
  }
}

.button {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
}

.inputContainer {
  display: inline-grid;
  flex: 1;
  grid-area: 1 / 1 / 2 / 3;
  grid-template-columns: 0 min-content;
  box-sizing: border-box;
  margin-left: 2px;
  cursor: text;

  &:after {
    grid-area: 1 / 2 / auto / auto;
    min-width: 2px;
    margin: 0;
    padding: 0;
    font: inherit;
    white-space: pre;
    border: 0;
    outline: 0;
    visibility: hidden;
    content: attr(data-value) ' ';
  }
}

.input {
  grid-area: 1 / 2 / auto / auto;
  min-width: 2px;
  height: auto;
}

.standardFilled {
  padding: spacing(0.75, 0, 1.25);
}
