@import 'ui-library/app';

.searchWrap {
  display: flex;
  flex: 1;
  gap: spacing(2);
  align-items: flex-start;

  @include l {
    margin-right: spacing(11.25);
  }

  @include xl {
    margin-right: spacing(0);
  }
}

.inputs {
  display: flex;
  flex: 1;
  gap: spacing(3);
  align-items: flex-start;

  & > * {
    flex: 1 1 0px;
    width: 0;
  }
}

.searchBtn {
  margin-top: spacing(0.5);
}

.userWrap {
  display: flex;
  gap: spacing(7);
  align-items: center;
}
