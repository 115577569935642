@import 'ui-library/app';

.root {
  display: flex;
  align-items: center;
}

.label {
  padding-left: spacing(2);
  cursor: pointer;
}
