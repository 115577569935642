@import 'ui-library/app';

.favorite {
  position: absolute;
  right: spacing(2);
  bottom: spacing(2);
  color: $c-primary;
  transition: $transition;
}

.imageContainer {
  position: relative;
  height: 150px;
  transition: $transition;

  @include l {
    height: 160px;
  }

  @include xl {
    height: 180px;
  }
}

.root {
  gap: spacing(2);
  justify-content: flex-end;

  &:hover {
    .imageContainer {
      background-color: rgb(8 13 19 / 25%);
    }

    .favorite {
      color: $c-1;
    }

    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.title {
  overflow-wrap: anywhere;
  @include font-h3($c-5);
  @include line-clamp(2);
}

.captionWrap {
  display: flex;
  gap: spacing(2);
  color: $c-5;
}

.captionIcon {
  color: $c-6;
}
