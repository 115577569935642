@import 'ui-library/app';

.caption {
  transition: $transition;

  @include line-clamp(2);
  @include font-h3($c-5);
}

.imageContainer {
  position: relative;
  width: 100%;
}

.image {
  display: block;
  width: 100%;
}

.info {
  display: flex;
  flex-direction: column;
  gap: spacing(0.5);
  word-break: break-word;
}

.author {
  @include line-clamp(2);
  @include font-text-italic-14($c-6);
}

.favoriteWrap {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding: spacing(0.5);
  background-color: $c-bg-primary;
}

.favorite {
  color: $c-primary;
}

.actionsWrap {
  position: relative;
  z-index: 10;
}

.actions {
  top: spacing(0);
  right: spacing(1);
}

.overlay {
  transition: $transition;
  transition-property: background-color;
}

.item {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  align-items: flex-start;
  padding-bottom: spacing(3);
  break-inside: avoid;

  &:hover {
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($c-text-primary, 0.35);
    }

    .caption {
      color: $c-text-accent;
    }

    .actions {
      visibility: visible;
      opacity: 1;
    }
  }
}
