@import 'ui-library/app';

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.formCol {
  flex: 0 1 auto;
  width: 264px;
  min-width: 120px;

  &:not(.formCol:first-child) {
    margin-left: 0;
  }
}

.control {
  background-color: $c-bg-primary;
  border: 1px solid $c-bg-light-grey;
}
