@import "ui-library/app";

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header {
  margin-top: spacing(3);
  margin-bottom: spacing(4);
}

.breadcrumbs {
  margin-top: spacing(3);
}
