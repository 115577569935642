@import "ui-library/app";

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: spacing(3);
  row-gap: spacing(3);

  @include l {
    grid-template-columns: repeat(4, 1fr);
  }

  @include xl {
    grid-template-columns: repeat(4, 1fr);
  }
}
