@import "ui-library/app";

.container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: spacing(3);
  row-gap: spacing(3);

  @include l {
    grid-template-columns: repeat(6, 1fr);
  }

  @include xl {
    grid-template-columns: repeat(7, 1fr);
  }
}
