@import 'ui-library/app';

.imageContainer {
  position: relative;
  height: 157px;
  transition: $transition;

  @include l {
    height: 232px;
  }

  @include xl {
    height: 318px;
  }
}

.status {
  @include font-b3-regular-14($c-text-tertiary);
  position: absolute;
  bottom: spacing(2);
  left: spacing(2);
  display: flex;
  visibility: hidden;
}

.actions {
  top: spacing(7);
  right: spacing(1);
}

.actionGuest {
  top: spacing(1);
  right: spacing(1);
}

.root {
  gap: spacing(1.25);
  justify-content: flex-end;

  &:hover {
    .imageContainer {
      background-color: rgb(53 50 49 / 35%);
    }

    .favoriteIconHover {
      visibility: visible;
      opacity: 1;
    }

    .actions {
      visibility: visible;
      opacity: 1;
    }

    .actionGuest {
      visibility: hidden;
      opacity: 0;
    }

    .status {
      visibility: visible;
      opacity: 1;
    }

  }
}

.image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.title {
  overflow-wrap: anywhere;
  @include font-h5-medium($c-text-title);
  @include line-clamp(1);
}

.captionWrap {
  display: flex;
  gap: spacing(2);
  color: $c-text-primary;
}

.captionIcon {
  color: $c-text-secondary;
}
