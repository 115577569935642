@import 'ui-library/app';

.description {
  padding: spacing(1, 8, 0);
}

.content {
  padding-top: spacing(5);
}

.actions {
  padding-top: spacing(3.5);
}
