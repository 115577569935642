@import 'ui-library/app';

$chip-background: rgb(210 213 217 / 50%);

.root {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: $chip-background;
  border-radius: 4px;
}

.label {
  @include font-text-regular-16($c-5);
}

.icon {
  display: flex;
  margin-left: 4px;
  color: $c-6;
  cursor: pointer;
}
