@import 'ui-library/app';
@import '../../styles/variables';

.root {
  display: flex;
  gap: spacing(3);
}

.image {
  width: $image-size;
  height: $image-size;
}

.lines {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: spacing(0.5);
}
