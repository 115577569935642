@import "ui-library/app";

.background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
}

.main {
    $column-gap: spacing(3);

    display: grid; 
    flex-grow: 1;  
    grid-column-gap: $column-gap;

    @include xs {
        $column-sides: calc(spacing(7) - $column-gap);

        grid-template-columns: $column-sides repeat(5, 1fr) $column-sides;
    }


    @include l {
        $column-sides: calc(spacing(8) - $column-gap);

        grid-template-columns: $column-sides repeat(6, 1fr) $column-sides;
    }

    @include xl {
        $column-sides: calc(spacing(10) - $column-gap);

        grid-template-columns: $column-sides repeat(7, 1fr) $column-sides;
    }
} 