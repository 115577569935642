@import 'ui-library/app';

.root {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: top;
  background: none;
  border: 0 solid transparent;
  outline: none;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);

  @include font-text-regular-16($c-5);

  &:disabled {
    -webkit-text-fill-color: $c-10;
    color: $c-10;
    opacity: 1;
  }

  &::placeholder {
    color: $c-6;
  }

  &:read-only {
    color: $c-10;
  }
}
