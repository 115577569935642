@import "ui-library/app";

.root {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
}

.actions {
  display: flex;
  gap: spacing(2);
}
