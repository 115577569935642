@import 'ui-library/app';

.root {
  .toast {
    display: flex;
    align-items: center;
    max-width: 360px;
    padding: spacing(2);
    overflow-wrap: anywhere;
    background-color: #fff;
    border: 1px solid $c-bg-light-grey;
    border-radius: 4px;
    box-shadow: none;

    @include font-b3-regular-14;
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: spacing(2);
  }

  .error {
    svg {
      color: $c-main-error;
    }
  }

  .success {
    svg {
      color: $c-main-done;
    }
  }
}
