@import 'ui-library/app';

.root {
  display: flex;
  flex-direction: column;
  gap: spacing(3);
}

.fieldCol {
  max-width: 200px;
}
