@import "ui-library/app";

.root {
  cursor: default;
}

.text {
  flex: 1;
  align-self: flex-start;
  margin-right: spacing(2);
}

.primary {
  @include line-clamp(2);
  @include font-text-regular-14($c-5);
}

.secondary {
  &:first-letter {
    text-transform: uppercase
  }

  @include font-text-regular-14($c-6);
}

.actionCaption {
  display: flex;
  align-items: center;
  @include font-text-regular-14($c-6);

  color: $c-6;
}

.validDate {
  display: flex;
  align-items: center;
  @include font-text-regular-14($c-6);

  color: $c-6;
}

.working {
  color: $c-primary;
}

.failed {
  color: $c-4;
}

.done {
  color: $c-13;
}

.button {
  padding: spacing(1, 2);
}

.iconButton {
  color: $c-6;
}

.actions {
  display: flex;
  gap: spacing(2);
}
