@import "ui-library/app";

.small {
  @include size(20px)
}

.normal {
  @include size(24px)
}

.large {
  @include size(32px)
}
