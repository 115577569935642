@import "ui-library/app";

.listItem {
  display: flex;
  align-items: center;
  color: $c-5;
  list-style: none;
  border-radius: 4px;
  cursor: pointer;
  transition: $transition;

  &:hover {
    color: $c-10;
  }
}

.large {
  padding: spacing(2);
}

.normal {
  padding: spacing(1.5, 2);
}

.small {
  padding: spacing(1, 2);
}

.none {
  padding: 0;
}

.selected {
  color: $c-5;
  background-color: $c-12;
}

.disableGutters {
  padding-right: 0;
  padding-left: 0;
}
