@import 'ui-library/app';

$illustration-light-color: #455366;
$illustration-dark-color: #716c62;

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  white-space: pre;
}

.title {
  margin-top: spacing(3);
  text-align: center;
}

.message {
  margin-top: spacing(3);
  text-align: center;
}

.title + .message {
  margin-top: spacing(1);
}

.button {
  margin-top: spacing(3);
}

.light {
  .icon {
    color: $illustration-light-color;
  }

  .title {
    @include font-h2-medium-24($c-1);
  }

  .message {
    @include font-text-regular-14($c-1);
  }
}

.dark {
  .icon {
    color: $illustration-dark-color;
  }

  .title {
    @include font-h2-medium-24($c-5);
  }

  .message {
    @include font-text-regular-16($c-5);
  }
}
