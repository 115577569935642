@import "ui-library/app";

$layout-card-border-radius: 4px;

.root {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 80px;
  padding: 0;
  color: $c-1;
  border-radius: $layout-card-border-radius;
  transition: $transition;

  @include l {
    height: 96px;
  }

  @include xl {
    height: 112px;
  }
}
