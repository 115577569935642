@import 'ui-library/app';

.root {
  &:not(.disabled) {
    cursor: pointer;
  }

  &.active {
    border-color: $c-6;
  }

  &.placeholder {
    color: $c-6;
  }
}

.placeholder {
  @include font-text-regular-16($c-6);
}

.button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}

.control {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.endAdornment {
  display: flex;
  align-items: center;
}
