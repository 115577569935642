@import 'ui-library/app';

$control-max-height: 80px;

.control {
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
}

.controlMultiline {
  flex-wrap: wrap;
  max-height: $control-max-height;
  overflow: auto;
}

.controlOneLine {
  overflow-x: auto;
  white-space: nowrap;
}

.controlOneLineStandard {
  &:after {
    position: absolute;
    right: 0;
    z-index: -1;
    display: block;
    width: 48px;
    height: 40px;
    background-image: linear-gradient(
      270deg,
      #fff 50%,
      rgb(255 255 255 / 50%) 74.76%,
      rgb(255 255 255 / 0%) 100%
    );
    visibility: visible;
    opacity: 0;
    transition: $transition;
    transition-property: opacity;
    content: '';
  }
}

.controlOneLineStandardShadowed {
  &:after {
    z-index: 10;
    visibility: visible;
    opacity: 1;
  }
}
