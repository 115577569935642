@import 'ui-library/app';

.paginationWrap {
  display: flex;
  justify-content: center;
  margin: spacing(4, 0);
}

.listWrap {
  flex: 1;
}

.root {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.placeholder {
  margin-top: 0;
}
