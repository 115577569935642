@import 'ui-library/app';

.menuButton {
  @include font-h3($c-5);
}

.menu {
  @include l {
    display: none;
  }
}

.tabs {
  display: none;

  @include l {
    display: flex;
  }
}

.hidden {
  display: none;
}
