@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(2);
  align-items: center;
}

.title {
  @include font-text-medium-18($c-1);
}

.icon {
  width: 32px;
  height: 32px;
}
