@import "ui-library/app";

.navLeftButton {
  background-color: $c-3;
}

.navRightButton {
  background-color: $c-1;
}

.root {
  display: flex;
  min-height: 100vh;
}

.placeholder {
  padding-right: spacing(7);

  @include l {
    padding-right: spacing(8);
  }

  @include xl {
    padding-right: spacing(10);
  }
}

.closeButton {
  position: absolute;
  top: spacing(4);
  right: spacing(4);
  z-index: 1;
}

.icon {
  color: $c-5;
}

