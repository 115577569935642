@import "ui-library/app";

.wrap {
  position: relative;
  width: 100%;
}

.root {
  position: absolute;
  top: -1px;
  right: 0;
  display: flex;
  flex: 1;
  gap: spacing(1);
  align-items: center;
  justify-content: flex-end;
}

.label {
  @include font-text-regular-16($c-5);
  width: spacing(13);
  
  @include l {
    margin-right: spacing(10);
  }
}
