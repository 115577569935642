@import 'ui-library/app';

.textarea {
  box-sizing: border-box;
  width: 100%;
  min-height: 136px;
  padding: 16px;
  text-overflow: ellipsis;
  background-color: $c-12;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  transition: $transition;
  transition-property: background-color, color, border;
  resize: vertical;

  @include font-text-regular-16($c-5);

  &::placeholder {
    color: $c-6;
  }

  &:disabled,
  &:read-only {
    color: $c-10;

    &::placeholder {
      color: $c-10;
    }
  }

  &:focus {
    border: 1px solid $c-6;
  }

  &:hover:not(&:disabled) {
    background-color: $c-11;
  }

  &.error {
    border: 1px solid $c-4;
  }
}

.root {
  position: relative;
  display: flex;
}
