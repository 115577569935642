@import 'ui-library/app';

.root {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
  padding: spacing(0.5);
  background-color: $c-bg-secondary;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  @include size(40px);
}

.favoriteIcon {
  width: 24px;
  height: 24px;
  color: $c-bg-light-grey;
  transition: $transition;
}

.favoriteIconFilled {
  width: 24px;
  height: 24px;
  color: $c-main-accent;
  transition: $transition;
}

.active {
  visibility: visible;
  opacity: 1;

  &:hover {
    .favoriteIconFilled {
      color: $c-main-accent-hover;
    }
  }
}

.favoriteHover:hover {
  background-color: $c-main-accent-hover;

  .favoriteIcon {
    color: $c-main-accent;
  }
}
