@import 'ui-library/app';

.button {
  display: flex;
  justify-content: flex-start;
  margin: spacing(3, 0);
  padding: spacing(1.5, 0);
  @include font-h3($c-5);
}

.input {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  touch-action: manipulation;
}
