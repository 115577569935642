@import 'ui-library/app';

.root {
  &.active {
    border-color: $c-6;
  }
}

.endAdornment {
  z-index: 10;
  cursor: pointer;
}

.endAdornmentBtn {
  display: flex;
}
