@import "ui-library/app";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: spacing(0, 2, 2, 2);
}

.title {
  @include font-text-regular-16($c-1);

  font-weight: 600;
}

.addTheme {
  margin-left: spacing(2);
  color: $c-11;
  background-color: $c-9;

  svg {
    transition: $transition;
  }

  &:hover {
    svg {
      color: $c-1;
    }
  }
}

.theme {
  display: flex;
}
