@import 'ui-library/app';

$switch-width: 40px;
$switch-height: 22px;
$slider-size: 18px;

.root {
  position: relative;
  display: inline-block;
  width: $switch-width;
  height: $switch-height;
  background-color: rgba($c-10, 0.5);
  border-color: transparent;
  border-radius: 20px;
  outline: none;
  cursor: pointer;
  transition-property: background-color;

  &.checked {
    background-color: rgba($c-primary, 0.25);
  }
}

.slider {
  position: absolute;
  bottom: 0;
  left: 0;
  width: $slider-size;
  height: $slider-size;
  background-color: $c-1;
  border-radius: 50%;
  transition: $transition;
  transition-property: transform, background-color;

  &.checked {
    background-color: $c-primary;
    transform: translateX(18px);
  }
}
