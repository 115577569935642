@import "ui-library/app";

.root {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: calc($zindex-modal - 1);
  display: flex;
  flex-direction: column;
  width: 700px;
  box-shadow: $shadow;
}

.animation {
  transition: $transition;
  transition-property: max-height;
}

.animationFrom {
  max-height: 0;
}

.animationTo {
 max-height: 450px;
}
