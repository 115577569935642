@import "ui-library/app";

.root {
  display: flex;
  gap: spacing(2);
}

.button {
  background-color: transparent;
}

.menu {
  min-width: 150px;
}
