@import 'ui-library/app';

.favorite {
  position: absolute;
  right: spacing(1);
  bottom: spacing(1);
  color: $c-main-accent;
  transition: $transition;
}

.notFavorite {
  position: absolute;
  right: spacing(1);
  bottom: spacing(1);
  color: $c-bg-light-grey;
  transition: $transition;
}

.favoriteButton {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
  background-color: $c-text-tertiary;
  border-radius: 50%;
  visibility: visible;
  @include size(40px);

  &:hover {
    .favorite {
      color: $c-main-accent-hover;
    }
  }
}

.notFavoriteButton {
  position: absolute;
  top: spacing(1);
  right: spacing(1);
  background-color: $c-bg-secondary;
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transition: $transition;
  @include size(40px);

  &:hover {
    background-color: rgb(239 218 196 / 100%);

    .notFavorite {
      color: $c-main-accent;
    }
  }
}
