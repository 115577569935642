@import 'ui-library/app';

.root {
  flex: 0 0 auto;
  padding: spacing(5, 5);
  background-color: $c-bg-dark;
}

.copyright {
  @include font-b3-regular-14($c-bg-secondary);
  opacity: 0.6;
}
